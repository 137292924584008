import React from 'react'
import Layout from '../layout'

interface Props {
  location: any
}

const FourZeroFour: React.FC<Props> = ({ location }) => (
  <Layout pageName="Not Found" location={location}>
    <div>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </Layout>
)

export default FourZeroFour
